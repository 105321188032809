.discretion {
    margin-top: 300px;
}

.discretion-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}

.discretion-para {
    margin-top: 20px;
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

.discretion-para span {
    font-family: "Poppins700";
}

.discretion-second-title {
    margin-top: 40px;
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}

.erp-modules {
    margin-top: 20px;
    display: flex;
    justify-self: start;
    align-items: center;
    gap: 5px;
    flex-wrap: wrap;
}

@media (max-width: 1400px) {
    .discretion {
        margin-top: 180px;
    }
    .discretion-para {
        margin-top: 18px;
        font-size: 15px;
    }
}


@media (max-width: 992px) {
    .discretion {
        margin-top: 500px;
    }
    .discretion-title, .discretion-second-title {
        font-size: 25px;
    }
    .discretion-para {
        font-size: 14px;
    }
}

@media (max-width: 768px) {
    .discretion-title, .discretion-second-title {
        font-size: 24px;
    }
}

@media (max-width: 576px) {
    .discretion {
        margin-top: 400px;
    }
    .discretion-para {
        font-size: 13px;
        line-height: 24px;
    }
}

@media (max-width: 476px) {
    .discretion-title, .discretion-second-title {
        font-size: 22px;
    }
    .discretion-para {
        font-size: 12px;
        line-height: 22px;
    }
}
