.pro-details-div {
    padding:60px 0px 0px;
} 
.arrow1-hidden {
    display: none;;
}

.pro-details-row:last-child {
    padding-top: 180px;
}
.pro-details-row:first-child {
    padding-top: 50px;
}


.project-details-image {
    display: flex;
    justify-content: center;
}

.pro-details-row {
    justify-content: center;
    align-items: center;
    position: relative;
}
.pro-img {
    width: 90%;
    height: 100%;
    object-fit: cover;
}
.pro-title {
    color:#2C2C2C;
    font-family: 'Poppins';
    font-size:27px ;
    font-weight: 500;
    margin-bottom: 20px;
}
.pro-description {
    color: #2C2C2C;
    font-family: 'Poppins';
    font-size: 16px;
    font-weight: 400;
    text-align: justify;
}
.arrow1 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%) ;
    bottom: -172px;
    width: 25%;
}
.removebg {
    background-image: unset !important;
}
@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .pro-title {
        font-size: 25px;
    }
    .pro-description {
        font-size: 15px;
    }
}


@media (max-width: 992px) {
    .pro-details-div {
        padding: 40px 0px 0px;
    }
    .pro-title {
        font-size:20px;
        margin-bottom: 15px;
    }
    .pro-description {
        font-size: 12px;
    }
    .arrow1 {
        width: 32%;
    }

}

@media (max-width: 768px) {
    .pro-details-div {
        padding: 25px 0px 0px;
    }
    .pro-details-row:first-child {
        flex-direction: column-reverse;
    }
    .pro-details-row{
        text-align: center;
    }
    .arrow1 {
        display: none;
    }
    .pro-title {
        font-size: 22px;
    }
    .pro-description {
        text-align: center;
        font-size: 12px;
    }
    .pro-details-row:last-child {
        padding-top: 60px;
    }
    .pro-img {
        width: 62%;
        margin-top: 10px;
    }
    .second-details-image {
        width: 74% !important;
        margin-left: -56px;
    }
}

@media (max-width: 576px) {
    .pro-details-row{
        text-align: center;
    }
    .arrow1 {
        bottom: -133px;
        width: 40%;
    }
}

@media (max-width: 476px) {
    .pro-title {
        font-size: 18px;
        margin-bottom: 10px;
    }
    .arrow1 {
        bottom: -147px;
        width: 50%
    }
    .pro-description {
        font-size: 11px;
    }

}
