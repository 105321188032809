.erp-contact-container {
    position: relative;
}

.erp-contact-content {
    margin-top: 80px;
    width: 100% ;
    padding-top: 80px;
    background-image: repeating-linear-gradient(
        to right,
        #B8B8B8,
        #B8B8B8 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
}

.erp-contact-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}


.erp-contact-list {
    list-style: none;
}


.contact-field {
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
}

.contact-para {
    color: var(--text, #525252);
    font-family: "Poppins400";
    font-size: 16px;
}
.contact-para span {
    color: #D93F48;
    font-family: "Poppins400";
    font-size: 16px;
}

.contact-input {
    resize: none;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 2px solid #E6E6E6;
}

.form-input-select {
    font-family: "Poppins400";
    font-size: 12px;
}


.phone-container {
    margin-left: unset !important;
    margin-right: unset !important;
    width: 100%;
}

.form-input-number, .form-input-phone {
    padding-left: unset !important;
    padding-right: unset !important;
}
#form-input-number {
    height: 50px;
    width: 100% !important;
    border-left: unset !important;
    border-top-left-radius: unset !important;
    border-radius: 4px;
    border: 2px solid #E6E6E6;
    border-bottom-left-radius: unset !important;
    padding-left: 10px;
}

#form-input-phone {
    height: 50px;
    padding: 10px;
    width: 100% !important;
    border-right: unset !important;
    border-radius: 4px;
    border: 2px solid #E6E6E6;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
}

.erp-contact-submit {
    margin-top: 30px;
    padding: 10px;
    width: 100%;
    border-radius: 4px;
    text-align: center;
    border: 1px solid #000;
    color: #000;
    background: #fff;
    font-family: "Poppins500";
    font-size: 19px;
    text-transform: capitalize;
    transition: .3s ease-in-out;
}
.erp-contact-submit:hover {
    background: #000;
    color: #fff
}

.contact-midline-container {
    display: flex;
    justify-content: center;
}

.contact-midline {
    margin-top: 30px;
    height: 96%;
    width: 1px;
    background: #DEDEDE;
}

.erp-contact-img {
    margin-top: 126px;
    width: 647px;
    margin-left: -112px;
}

@media (max-width: 1200px) {
    .erp-contact-img {
        margin-top: 170px;
        width: 571px;
    }
}

@media (max-width: 992px) {
    .erp-contact-img {
        display: none;
    }
    .contact-midline-container {
        display: none;
    }
}

@media (max-width: 576px) {
    .erp-contact-para {
        font-size: 16px;
        line-height: 27px;
    }
    .erp-contact-item label {
        font-size: 16px;
    }
    .erp-contact-item img {
        width: 12px;
    }
    .erp-contact-item:last-child img {
        width: 18px;
    }
    .contact-para {
        font-size: 14px;
        margin-bottom: 10px !important;
    }
    .erp-contact-submit {
        font-size: 14px;
    }
}
