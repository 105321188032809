.project-video-div {
    margin-top: 150px;
    text-align: center;
    position: relative;
}

.video-title {
    color:#292929;
    font-family: 'Poppins';
    font-size:27px;
    font-weight: 500;
    margin-bottom:20px;
}
.video-text {
    color:#313131;
    font-family: 'Poppins';
    font-size:16px;
    font-weight: 400;
    padding: 0px 320px;
    margin-bottom: 40px;
}
.arrow2 {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -250px;
    width: 13%;
}
.video-div {
    position: relative;
}
.video-div iframe {
    background-color: #0C1F30;
    padding: 20px;
    border-radius: 46px;
}
.video-back-div {
    background-color: rgba(0, 0, 0, 0.12);
    height: 320px;
    width: 40%;
    border-radius: 29px;
    position: absolute;
    right: 22%;
    bottom: -8%;
    z-index: -1;
}
@media (max-width: 1400px) {
    .video-back-div {
        width: 44%;
        right: 17%;

    }
}


@media (max-width: 1200px) {
    .video-text {
        padding:0px;
    }
    .video-back-div {
        width: 50%;
        right: 11%;

    }
    .arrow2 {
        top: -215px;
        width: 15%;
    }
}


@media (max-width: 992px) {
    .video-div iframe {
        width: 100%;
    }
    .video-back-div {
        display: none;
    }
    .video-text {
        padding:0px;
    }

    .arrow2 {
        top: -190px;
        width: 20%;
    }

}

@media (max-width: 768px) {
    .arrow2 {
        top: -192px;
        width: 22%;
    }


}

@media (max-width: 567px) {
    .video-text {
        padding:0px;
    }

    .arrow2 {
        top: -222px;
        width: 32%;
    }
}

@media (max-width: 492px) {


}