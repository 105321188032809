.para-container {
    margin-top: 60px;
}

.para-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 28px;
    text-transform: capitalize;
}

.para-desc {
    margin-top: 30px;
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
}

@media (max-width: 1200px) {
    .para-desc {
        margin-top: 26px;
        font-size: 15px;
    }
    .para-title {
        font-size: 26px;
    }
}

@media (max-width: 992px) {
    .para-desc {
        margin-top: 24px;
        font-size: 14px;
    }
    .para-title {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .para-desc {
        margin-top: 20px;
        font-size: 13px;
    }
    .para-title {
        font-size: 22px;
    }
    .about-page-img {
        display: flex;
        justify-content: center;
    }
    .about-page-img img {
        width: 89%;
    }
}

@media (max-width: 576px) {
    .para-desc {
        margin-top: 18px;
        font-size: 12px;
    }
    .para-title {
        font-size: 20px;
    }
    .about-page-img img {
        width: 76%;
    }
}
@media (max-width: 476px) {
    .para-desc {
        margin-top: 16px;
        font-size: 11px;
    }
    .para-title {
        font-size: 18px;
    }
}