
#color1 {
    position: absolute;
    right: -0px;
    top: -200px;
    z-index: -1;
}
.color-div1 {
    background-image: url(../../asset//images/ProjectDetails/pro_hero_img.png);
    position: relative;
}
.color-div2 {
    position: relative;
}
.color-div3 {
    position: relative;
}
#color2 {
    position: absolute;
    left: -817px;
    top: -591px;
    z-index: -1;
}
#color3 {
    position: absolute;
    left: 0px;
    top: -700px;
    z-index: -1;
}   
#color4 {
    position: absolute;
    z-index: -1;
    left: 0px;
    top: -370px;
}
#color5 {
    position: absolute;
    left: 0px;
    z-index: -1;
}
#color6 {
    position: absolute;
    right: 0px;
    z-index: -1;
}




@media (max-width: 1200px) {
    #color1 {
        right: 0px;   
        top: -920px;    
       
    }
      #color2 {
            left: -372px;
            width: 100%;
        }
       
    #color3 {
        left: 0px;
        width: 100%;
        
        
    }
    #color4 {
        left: 0px;
        width: 100%;
       
        
    }
    #color5 {
        
        left:0px;
        width: 100%;

    }

}


@media (max-width: 992px) {
    #color2 {
        display: none;
    }
}

