.integrate {
    margin-top: 100px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.integrate-image {
    padding: 0 !important;
    border: 15px solid #000;
    border-radius: 19px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 350px;
}

.integrate-img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.integrate-arrow {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    top: 100%;
    width: 250px;
}

.integrate-title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.integrate-title-h {
    margin: 0;
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: capitalize;
}

.integrate-para {
    margin-top: 20px;
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    font-style: normal;
    line-height: 28px;
}

.integrate-image-end {
    display: flex !important;
    justify-content: end !important;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .integrate {
        margin-top: 132px;
    }
    .integrate-arrow {
        top: 24px;
        width: 180px;
    }
    .integrate-title-h {
        font-size: 22px;
    }
    .integrate-title-img {
        width: 130px;
    }
    .integrate-para {
        font-size: 14px;
        line-height: 26px;
    }
    .integrate-image {
        width: 370px;
        height: 330px;
        border: 14px solid #000;
    }
    .integrate-img {
        border-radius: 4px;
    }
}


@media (max-width: 992px) {
    .integrate-image {
        width: 300px;
        height: 280px;
    }
    .integrate-para {
        font-size: 13px;
        line-height: 23px;
    }
    .integrate-title-img {
        width: 110px;
    }
    .erp-integrates {
        padding-top: 1px;
        margin-top: 80px;
    }
    .integrate-title-h {
        font-size: 20px;
    }
    .integrate-arrow {
        width: 160px;
    }
}

@media (max-width: 768px) {
    .integrate-arrow {
        display: none;
    }
    .integrate-image {
        width: 100%;
        height: 280px;
        margin-top: 30px;
    }
    .integrate {
        flex-direction: column-reverse !important;
        margin-top: 80px;
    }
}

@media (max-width: 576px) {
    .integrate-title-h {
        font-size: 18px;
    }
    .integrate-title-img {
        width: 100px;
    }
    .integrate-para {
        font-size: 12px;
        line-height: 20px;
        margin-top: 12px;
    }
    .integrate-image {
        width: 90%;
        margin: 30px auto 0;
    }
}

@media (max-width: 476px) {
    .integrate-title-h {
        font-size: 16px;
    }
    .integrate-title-img {
        width: 90px;
    }
    .integrate-para {
        font-size: 11px;
        line-height: 18px;
        margin-top: 10px;
    }
}