footer {
    margin-top: 180px;
    background-color: #000;
    color: #fff;
    padding: 20px;
}


.footer-content {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.footer-text {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}
.footer-text img {
    width: 150px;
    margin-bottom: 15px;
}
.footer-text h3 {
    font-family: 'Inter', sans-serif;
    letter-spacing: 1px;
    font-size: 12px;
    font-weight: 600;
}
.footer-text p {
    max-width: 203px;
    color: #ffffffec;
    font-size: 13px;
    margin-top: 5px;
    line-height: 1.5;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.footer-text p:hover {
    color: var(--secondary-color);
} 

#footer-logo img {
    margin-top: -14px;
}

#footer-logo h1 {
    color: #FFF;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

#footer-logo p {
    cursor: unset;
    margin: unset;
    font-size: 15px;
    letter-spacing: 1px;
    font-family: "Poppins300";
}
#footer-logo p:hover {
    color: #fff;
}
#footer-touch i {
    margin-right: 8px;
    margin-top: 10px;
}
#footer-touch p {
    cursor: unset;
    margin-bottom: 20px;
    font-family: "Poppins300";
}
#footer-touch p:hover {
    color: #fff;
}
.footer-touch-text {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.footer-touch-email {
    margin-top: 15px;
}
.footer-touch-email input[type="email"] {
    width: 180%;
    background: none;
    border: none;
    border-bottom: 1px solid #898989;
    outline: none;
    padding: 10px 0;
    margin-bottom: 25px;
    color: #fff;
    font-size: 12px;
}
.footer-touch-email input[type="email"]::placeholder {
    color: #898989;
}
footer a {
    color: #fff;
    transition: .4s ease
}
footer a:hover {
    color: var(--secondary-color);
} 
#footer-touch a {
    /* margin-left: 130px; */
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 20px;
    letter-spacing: 1px;
    background: transparent;
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    transition: .4s ease-in-out;
}
#footer-touch a:hover {
    background: #fff;
    color: #242424;
}
.footer-text button {
    border: none;
}
#footer-links a {
    transition: .3s ease-in-out;
    margin-top: 10px;
}
#footer-links a:hover {
    color: var(--secondary-color);
}


.footer-down {
    width: 100%;
    /* margin: 70px auto; */
    margin: 70px auto 12px;
    border-top: 1px solid #fff;
}
.footer-links {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.links-text {
    display: flex;
    gap: 20px;
}
.links-text p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.links-text p:hover {
    color: var(--secondary-color);
}
.footer-down .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.links svg {
    margin-left: 15px;
    cursor: pointer;
    transition: .3s ease-in-out;
    fill: #fff;
}
.links svg:hover {
    transform: scale(1.1);
}
.footer-copyright {
    max-width: 400px;
    text-align: center;
}
.footer-down-para { 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.footer-down-para p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    width: 55%;
}

.footer-logos {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 5px;
    margin-left: -7px;
}
.footer-logos img:first-child {
    width: 50px;
}
.footer-logos img:last-child {
    width: 120px;
    margin-bottom: 5px;
}


@media (max-width: 992px) {
    .footer-content {
        padding-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        gap: 25px
    }
    #footer-logo {
        width: 100%;
    }
    #footer-logo p {
        max-width: none;
    }
    .footer-text {
        margin: 30px 10px 10px;
    }
}

@media (max-width: 767px) {
    .footer-down {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .footer-links {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .links-text p {
        margin: unset;
    }
    .footer-down-para p {
        width: 81%;
    }
    footer {
        margin-top: 100px;
    }
} 
@media (max-width: 497px) {
    .footer-content {
        justify-content: flex-start;
    }
}

