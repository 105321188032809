.home-service {
    position: relative;
    display: flex;
    justify-content: space-between !important;
    align-items: center !important;
    margin-top: 250px;
}

.home-service-title {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
}

.home-service-para {
    margin-top: 20px;
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 29px;
}
.home-service-para span {
    font-family: "Poppins700";
}

.services-arrow {
    position: absolute;
    top: 120%;
    left: 47%;
    transform: translateX(-50%);
    width: 250px;
}
.services-arrow img {
    width: 100%;
}

.home-service-img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translateY(-45%);
    z-index: -1;
}



.home-service .home-service-link {
    display: inline-block;
}

@media (max-width: 992px) {
    .services-arrow {
        display: none;
    }
    .home-service-image {
        height: 320px;
    }
    .home-service-img {
        top: unset;
        transform: unset;
        
    }
}

@media (max-width: 768px) {
    .home-service {
        margin-top: 100px;
    }
    .second-service-img {
        width: 85%;
        top: -156px;
    }
}

@media (max-width: 576px) {
    .home-service-title {
        font-size: 22px;
    }
    .home-service-para {
        font-size: 14px;
        line-height: 26px;
        margin-top: 14px;
    }
    
}

@media (max-width: 500px) {
    .home-service {
        margin-top: 50px;
    }
}