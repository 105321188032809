.Partner {
    margin-top: 100px;
    width: 100% ;
    padding-top: 100px;
    background-image: repeating-linear-gradient(
        to right,
        #B8B8B8,
        #B8B8B8 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Partner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.Partner h1 {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    text-transform: uppercase;
    text-align: center;
}
.Partner p {
    width: 60%;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

.splide {
    margin-top: 40px;
    width: 100%;
    overflow: hidden;
}

/* .splide-img {
    width: 100px;
} */

.splide__list {
    align-items: center;
}



/* @media (max-width: 576px) {
    .splide-img {
        max-width: 200px;
        height: 70px;
    }
} */

/* .Partner .splide__list {
    align-items: center;
    gap: 60px;
}

.Partner .splide__slide {
    flex-shrink: 1;
} */

@media (max-width: 992px) {
    .Partner {
        margin-top: 100px;
    }
}
@media (max-width: 576px) {
    .Partner h1{
       font-size: 22px;
    }
    .Partner p{
        font-size: 14px;
        width: unset;
    }
}
/* End Partner */