.what-we-do {
    margin-top: 100px;
    width: 100%;
    padding-top: 100px;
    background-image: repeating-linear-gradient(to right,
            #B8B8B8,
            #B8B8B8 10px,
            transparent 10px,
            transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.what-we-do-title {
    text-align: center;
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 36px;
    text-transform: capitalize;
}

.what-we-do-para {
    max-width: 40%;
    margin: 15px auto 20px;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .what-we-do-title {
        font-size: 32px;
    }
    .what-we-do-para {
        font-size: 15px;
        line-height: 25px;
    }
}


@media (max-width: 992px) {
    .what-we-do-title {
        font-size: 30px;
    }
    .what-we-do-para {
        max-width: 100%;
        margin: 11px 120px 20px;
    }
}

@media (max-width: 768px) {
    .what-we-do-title {
        font-size: 28px;
    }
    .what-we-do-para {
        max-width: unset;
        margin: 11px 0px 20px;
        font-size: 14px;
        line-height: 23px;
    }
}

@media (max-width: 576px) {
    .what-we-do-title {
        font-size: 26px;
    }
    .what-we-do-para {
        max-width: unset;
        margin: 9px 0px 20px;
    }
}

@media (max-width: 476px) {
    .what-we-do-title {
        font-size: 25px;
    }
    .what-we-do-para {
        font-size: 13px;
        line-height: 21px;
    }
}