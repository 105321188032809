.hero-erpsolutions-container {
    position: relative;
}

.hero-erpsolutions {
    margin-top: 200px;
}

.hero-title {
    color: #000;
    font-family: "Poppins300";
    font-size: 41px;
    text-transform: uppercase;
}

.hero-title span {
    font-family: "Poppins500";
}

.hero-para {
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins300";
    font-size: 16px;
    line-height: 28px;
    max-width: 88%;
}

.hero-image {
    position: absolute;
    top: -150px;
    right: 0;
    width: 40%;
    /* width: 100%; */
}

.hero-links {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 20px;
}

.hero-link {
    border: 1.6px solid #000;
    padding: 12px 34px;
    color: #000;
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    letter-spacing: 1.12px;
    text-transform: uppercase;
    cursor: pointer;
    transition: .3s ease-in-out;
}

.hero-link:hover {
    background: #000;
    color: #fff;
}

.hero-erpsolutions-content {
    padding-top: 100px;
}

@media (max-width: 3000px) {
    .hero-image {
        width: 649px;
    }
}


@media (max-width: 1400px) {
    .hero-para {
        font-size: 15px;
        line-height: 26px;
    }
    .hero-title {
        font-size: 38px;
    }
    .hero-link {
        padding: 12px 30px;
        font-size: 13px;
    }
    .hero-image {
        width: 42%;
    }
}



@media (max-width: 1200px) {
    .hero-image {
        top: -108px;
        width: 41%;
    }
    .hero-title {
        font-size: 32px;
    }
    .hero-para {
        font-size: 14px;
        line-height: 25px;
    }
    .hero-links {
        margin-top: 34px;
    }
    .hero-link {
        padding: 10px 22px;
        font-size: 12px;
    }
    .hero-erpsolutions-content {
        padding-top: 53px;
    }
}


@media (max-width: 992px) {
    .hero-erpsolutions-content {
        padding-top: unset;
    }
    .hero-image {
        top: 100%;
        width: 450px;
    }
    .hero-link {
        padding: 11px 22px;
    }
}

@media (max-width: 768px) {
    .hero-erpsolutions-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .hero-erpsolutions {
        margin-top: 145px;
    }
    .hero-para {
        text-align: center;
    }
    .hero-links {
        margin-top: 15px;
        justify-content: center;
    }
    .hero-image {
        top: 140%;
        width: 370px;
    }
}

@media (max-width: 576px) {
    .hero-title {
        font-size: 28px;
    }
    .hero-para {
        font-size: 13px;
        line-height: 24px;
    }
    .hero-link {
        padding: 10px 20px;
        font-size: 11px;
    }
    .hero-image {
        width: 320px;
    }
    .hero-links {
        gap: 10px;
    }
}

@media (max-width: 476px) {
    .hero-image {
        width: 320px;
    }
    .hero-title {
        font-size: 26px;
    }
    .hero-para {
        font-size: 12px;
        line-height: 22px;
    }
    .hero-link {
        padding: 10px 12px;
        font-size: 10px;
    }
    .hero-image {
        width: 300px;
    }
}
