.border-top-section {
    width: 100%;
    padding-top: 1px;
    margin-top: 60px;
    background-image: repeating-linear-gradient(to right,
            #B8B8B8,
            #B8B8B8 10px,
            transparent 10px,
            transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.about-page-img {
    margin-top: 100px;
    width: 100%;
}
.about-page-img img {
    width: 100%;
    object-fit: contain;
}

