.project-arrow {
    width: unset !important;
    height: unset !important;
}

.project-img {
    width: 100%;
    height: 100%;
}

.project {
    position: relative;
    overflow: hidden;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 8px;
    height: 92%;
}

.project-content {
    padding: 10px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    position: absolute;
    top: 200%;
    transition: .5s ease-in-out;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.6));
}
.project-content h1 {
    color: #FFF;
    text-align: center;
    font-family: "Poppins700";
    font-size: 18px;
    line-height: 28px;
    letter-spacing: 1px;
}
.project-content p {
    color: #FFF;
    font-family: "Poppins300";
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
    width: 85%;
    text-align: center;
}
.project-content i {
    color: #fff;
}


.project:hover .project-content {
    top: 0;
} 

.projects-link {
    margin-top: 80px;
    color: #000;
    text-transform: uppercase;
    border: 1px solid #000;
    padding: 12px 50px;
    cursor: pointer;
    letter-spacing: 1px;
    font-family: "Poppins300";
    font-size: 16px;
    transition: .3s ease-in-out;
}
.projects-link:hover {
    background: #000;
    color: #fff;
}

@media (max-width: 1400px) {
    .project-content h1  {
        font-size: 17px;
        line-height: unset;
    }
    .project-content p {
        font-size: 15px;
        line-height: 20px;
    }
}
@media (max-width: 1200px) {
    .project-content p {
        font-size: 14px;
        line-height: 18px;
    }
}
@media (max-width: 992px) {
    .project-content h1  {
        font-size: 16px;
        line-height: unset;
    }
}

@media (max-width: 576px) {
    
}
/* End Projects */