.home-hero {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120vh;
    width: 100%;
    overflow: hidden;
}

.home-hero-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: -3;
}

#left-light {
    position: absolute;
    z-index: -1;
    left: 0;
    width: 60%;
    top: -10px;
}

#center-light {
    position: absolute;
    left: 0;
    width: 60%;
    top: -10px;
    z-index: -1;

}

.home-hero-video-container {
    position: relative;
    background: unset;
    z-index: -2;
}

.home-hero-video {
    position: absolute;
    object-fit: contain;
    width: 100%;
    bottom: 50%;
    left: 50%;
    z-index: -2;
    transform: translate(-50%, 43%);
}

.home-hero-content {
    margin-top: -50px;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.home-hero-title {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    color: #FFF;
    text-align: center;
    font-family: "Poppins300";
    font-size: 40px;
    letter-spacing: 4px;
    text-transform: uppercase;
}

.home-hero-title span:first-child {
    font-family: "Poppins500";
} 

.home-hero-para {
    margin-top: 25px;
    margin-bottom: 25px;
    color: #FFF;
    font-family: "Poppins300";
    font-size: 16px;
    line-height: 28px; 
    letter-spacing: 0.18px;
    text-align: justify;
    max-width: 80%;
}

.home-hero-img {
    width: 12px;
    cursor: pointer;
    margin-top: 20px;
}

.section-border {
    position: absolute;
    bottom: -130px;
    z-index: -2;
    width: 100%;
}

.home-hero-link {
    margin-top: 10px;
    padding: 10px 32px;
    border: 1px solid #fff;
    color: #FFF;
    font-family: "Poppins200";
    font-size: 13px;
    letter-spacing: 0.88px;
    text-transform: uppercase;
    transition: .3s ease;
}
.home-hero-link:hover {
    border: unset !important;
    background: #fff;
    color: #0A0A0A;
    font-family: "Poppins400";
}

@media (max-width: 2800px ) {
    .section-border {
        bottom: -153px;
    }
}

@media (max-width: 1600px) {
    .section-border {
        bottom: -130px;
    }
}

@media (max-width: 1480px) {
    .section-border {
        bottom: -120px;
    }

}

@media (max-width: 1440px) {
    .section-border {
        bottom: -100px;
    }
    .home-hero-para {
        max-width: 90%;
    }
}

@media (max-width: 1200px) {
    .section-border {
        bottom: -90px;
    }
    .home-hero-para {
        max-width: 92%;
    }
}

@media (max-width: 992px) {
    .home-hero-content {
        align-items: center;
        margin-top: -300px;
    }
    .section-border {
        bottom: -65px;
    }
    .home-hero-logo {
        width: 280px;
    }
    .home-hero-para {
        text-align: center;
        max-width: 65%;
        font-size: 14px;
        line-height: 24px;
    }
    .home-hero-video {
        transform: translate(-50%, 82%);
        width: 64%;
    }
}

@media (max-width: 768px) {
    .home-hero-para {
        max-width: unset;
    }
    .section-border {
        bottom: -45px;
    }
    .home-hero-video {
        transform: translate(-50%, 95%);
        width: 75%;
    }
}

@media (max-width: 576px) {
    .home-hero-para {
        max-width: 90%;
        font-size: 14px;
    }
    .home-hero-title {
        gap: 5px;
        font-size: 36px;
    }
    .section-border {
        bottom: -33px;
    }
    .home-hero-logo {
        width: 240px;
    }
    .home-hero-video {
        transform: translate(-50%, 95%);
        width: 73%;
    }
}

@media (max-width: 476px) {
    .section-border {
        bottom: -27px;
    }
    .home-hero-logo {
        width: 220px;
    }
    .home-hero-video {
        width: 71%;
    }
    #left-light {
        width: 141%;
    }
}