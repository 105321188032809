.header {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../../../../asset/images/aboutus/header.png") no-repeat;
    background-size: cover;
    padding: 160px 0 40px;
}

.header-container {
 
}

.header-title {
    color: #FFF;
    font-family: "Poppins400";
    font-size: 15px;
    text-transform: uppercase;
}
.header-title span {
    font-family: "Poppins200";
}

.header-desc {
    margin-top: 20px;
    color: #FFF;
    font-family: "Poppins400";
    font-size: 29px;
    letter-spacing: -0.6px;
    text-transform: uppercase;
}