* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  width: 100%;
  overflow-x: hidden;
  
}

a {
  text-decoration: none !important; 
}

ul {
  padding: 0 !important;
}


@font-face {
  font-family: 'Inter Thin';
  src: local('Inter Thin'), url(./asset/fonts/Inter-Thin.ttf) format('woff2');

}
@font-face {
  font-family: 'Inter';
  src: local('Inter'), url(./asset/fonts/Inter-Regular.ttf) format('woff2');

}


/* Poppins */
@font-face {
  font-family: "Poppins100";
  src: local('Poppins100'), url(./asset/fonts/Poppins/Poppins-Thin.ttf) format('woff2');
}
@font-face {
  font-family: "PoppinsItalic100";
  src: local('PoppinsItalic100'), url(./asset/fonts/Poppins/Poppins-ThinItalic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins200";
  src: local('Poppins200'), url(./asset/fonts/Poppins/Poppins-ExtraLight.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic200";
  src: local('PoppinsItalic200'), url(./asset/fonts/Poppins/Poppins-ExtraLightItalic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins300";
  src: local('Poppins300'), url(./asset/fonts/Poppins/Poppins-Light.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic300";
  src: local('PoppinsItalic300'), url(./asset/fonts/Poppins/Poppins-LightItalic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins400";
  src: local('Poppins400'), url(./asset/fonts/Poppins/Poppins-Regular.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic400";
  src: local('PoppinsItalic400'), url(./asset/fonts/Poppins/Poppins-Italic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins500";
  src: local('Poppins500'), url(./asset/fonts/Poppins/Poppins-Medium.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic500";
  src: local('PoppinsItalic500'), url(./asset/fonts/Poppins/Poppins-MediumItalic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins600";
  src: local('Poppins600'), url(./asset/fonts/Poppins/Poppins-SemiBold.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic600";
  src: local('PoppinsItalic600'), url(./asset/fonts/Poppins/Poppins-SemiBoldItalic.ttf) format('woff2');

}
@font-face {
  font-family: "Poppins700";
  src: local('Poppins700'), url(./asset/fonts/Poppins/Poppins-Bold.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic700";
  src: local('PoppinsItalic700'), url(./asset/fonts/Poppins/Poppins-BoldItalic.ttf) format('woff2');
}
@font-face {
  font-family: "Poppins800";
  src: local('Poppins800'), url(./asset/fonts/Poppins/Poppins-ExtraBold.ttf) format('woff2');

}
@font-face {
  font-family: "PoppinsItalic800";
  src: local('PoppinsItalic800'), url(./asset/fonts/Poppins/Poppins-ExtraBoldItalic.ttf) format('woff2');

}
/* End Poppins */
