
.App {
  position: relative;
  overflow: hidden;
  max-width: 100%;
  opacity: 0.3;
  transition: opacity 1s ease-in-out;
}
  
 .loading-container {
  opacity: 1;
  transition: opacity 1s ease-in-out; /* Smooth transition for fading */
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Background during loading */
  z-index: 1000; /* Ensure it is above all other content */
}

.fade-out {
  opacity: 0;
  pointer-events: none; /* Avoid interaction during fade-out */
}

.App.show-content {
  opacity: 1;
  transition: opacity 1s ease-in-out;
}