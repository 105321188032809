.projects-container {
    margin-top: 180px;
    width: 100% ;
    padding-top: 80px;
    background-image: repeating-linear-gradient(
        to right,
        #B8B8B8,
        #B8B8B8 10px, 
        transparent 10px,
        transparent 20px 
      );
      background-size: 16px 1px;
      background-repeat: repeat-x;
      background-position: 0 0; 
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.projects-container h1 {
    color: #2C2C2C;
    font-family: "Poppins500";
    font-size: 26px;
    line-height: normal;
    text-transform: capitalize;
    text-align: center;
}
.projects-container p {
    width: 74%;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

.projects-container p span {
    font-family: "Poppins600";
}

@media (max-width: 992px) {
    
}
@media (max-width: 768px) {
    .projects-container p {
        width: unset;
    }
    .projects-container {
        margin-top: 125px;
    }
}
@media (max-width: 576px) {
    .projects-container h1  {
       font-size: 22px;
    }
    .projects-container p {
        width: unset;
        font-size: 14px;
    }
}