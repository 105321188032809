.erp-services {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 82%;
    margin: 30px auto 0;
}

.erp-service-title {
    margin-top: 15px;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins500";
    font-size: 18px;
    text-transform: capitalize;
}

.erp-service-para {
    margin-top: 10px;
    color: #2C2C2C;
    text-align: center;
    font-family: "Poppins400";
    font-size: 16px;
    line-height: 28px;
}

@media (max-width: 1400px) {
    .erp-service-para {
        font-size: 14px;
        line-height: 23px;
    }
}

@media (max-width: 1200px) {
    .erp-service-para {
        margin-top: 8px;
        font-size: 13px;
        line-height: 22px;
    }
    .erp-service-title {
        font-size: 17px;
    }
}

@media (max-width: 992px) {
    .erp-service-title {
        font-size: 14px;
    }
    .erp-service-para {
        margin-top: 6px;
        font-size: 12px;
        line-height: 21px;
    }
    .erp-service-img {
        width: 22px;
    }
}

@media (max-width: 768px) {

}

@media (max-width: 576px) {

}

@media (max-width: 476px) {
    .erp-service-para {
        margin-top: 1px;
        font-size: 11px;
        line-height: 19px;
    }
    .erp-service-title {
        font-size: 13px;
    }
    .erp-service-img {
        width: 20px;
    }
}