.mobile-hero {
    height: 683px;
    padding-top: 60px;
    
}
.mobile-hero-container {
    height:100%;
}

.mobile-hero-floating-btn-1 {
    position: absolute;
    top: 10px;
    right: 220px;
}

.mobile-hero-floating-btn-1 img {
    width: 180px !important;
}

.mobile-hero-floating-btn-2 {
    position: absolute;
    top: 9px;
    right: 540px;
}

.mobile-hero-floating-btn-2 img {
    width: 220px !important;
}

.device-gallery-col-container {
    display: flex;
    justify-content: center;
}

@keyframes floatVertical {
    0%, 100% {
        transform: translate(0, -10px);
    }
    50% {
        transform: translate(0, 10px);
    }
}

@keyframes floatHorizontal {
    0%, 100% {
        transform: translate(-10px, 0);
    }
    50% {
        transform: translate(10px, 0);
    }
}

.mobile-hero-floating-btn-1 img {
    animation: floatVertical 6s ease-in-out infinite;
    display: none;
}

.mobile-hero-floating-btn-2 img {
    animation: floatHorizontal 6s ease-in-out infinite;
    display: none;
}
.mobile-hero-image-content {
    padding-top: 70px;
    position: relative;
}
.mobile-hero-image-content img {
    width: 140%;
    margin-top: -110px;
    margin-left: -50px;
}

.mobile-hero-text-container {
    height: 100%;
    display: flex;
    align-items: center;
}

.mobile-hero-container {
    display: flex;
    align-items: center;
}

.mobile-hero-text-content {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.mobile-hero-text-title {
    color: #000;
    font-family: "Poppins300";
    font-size: 41px;
    text-transform: uppercase;
}

.mobile-hero-text-des {
    color: #2C2C2C;
    text-align: justify;
    font-family: "Poppins300";
    font-size: 16px;
    line-height: 28px;
    max-width: 85%;
}

.mobile-hero-text-title{
    display: flex;
    gap: 9px;
}

.mobile-hero-text-title-words .Typewriter__wrapper {
    background: linear-gradient(90deg, #b51e8e 64.91%, #7d1964 95.63%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.device-gallery-col-content {
    margin-bottom: 30px;
}
@media (max-width: 768px) {
    .mobile-hero-text-title{
        font-size: 30px;
        text-align: center;
        font-family: "Poppins400";
    }
    .mobile-hero {
        height: 780px;
        padding-top: 140px
    }
    .mobile-hero-image-content img {
        width: 116%;
        margin: unset;
    }
    .mobile-hero-image-content {
        padding-top: 0px;
    }

    .mobile-hero-text-content {
        align-items: center;
    }

    .mobile-hero-text-des { 
        text-align: center;
    }
    .devices-gallery {
        padding-top: 0px !important;
    }
}

@media (min-width: 1400px) {
    .mobile-hero-floating-btn-2 img, .mobile-hero-floating-btn-1 img {
        display: block;
    }
}

@media (max-width: 476px) {
    .mobile-hero {
        height: 720px;
        padding-top: 120px;
    }
}