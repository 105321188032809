.ai-industry-services-container {
    padding-top: 50px;
    background-image: repeating-linear-gradient(to right, #B8B8B8, #B8B8B8 10px, transparent 10px, transparent 20px);
    background-size: 16px 1px;
    background-repeat: repeat-x;
    background-position: 0 0;
}

.ai-industry-services-content {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.ai-industry-services-content  .ai-services-arrow img{
    animation: float 3s ease-in-out infinite;

}

.ai-industry-services-title {
    display: flex;
    justify-content: center;
    color: #2C2C2C;
    font-size: 20px;
    font-family: "Poppins500";
}

.ai-industry-services-chart-container {
    padding-top: 20px;
}

.ai-industry-services-chart-content {
    display: flex;
    justify-content: center;
}

.ai-industry-services-chart-content img {
    width: 100%;
    padding-left: 44px;
}

.ai-industry-services-des {
    display: flex;
    justify-content: center;
    padding-top: 50px;
}

.ai-industry-services-des span {
    width: 20%;
    text-align: center;
}

.ai-industry-services-container .ai-services-arrow {
    padding-top: 10px;
}

.ai-industry-services-container .hero-links {
    padding-top: 30px;
    justify-content: center;
    margin-top: unset;
}

.ai-industry-services-title span {
    text-align: center;
}

.industry-chart-small {
    display: none; 
    width: 120px !important;
}

.industry-chart-large {
    display: block;
}

@media only screen and (max-width: 600px) {
    .industry-chart-large {
        display: none; 
    }
    
    .industry-chart-small {
        display: block;
    }
}

@media (max-width: 992px) {
    .ai-industry-services-chart-content img {
        padding-left:unset ;
    }
    .ai-industry-services-des span {
        width: 70%;
    }
}