.devices-gallery{
    padding-top: 50px;
    
}

.devices-gallery-container{
    overflow: hidden;
}
.device-gallery-col-content img {
    width: 94%;
}

@media (max-width: 768px) {
    .device-gallery-col-content img {
        width: 100%;
    }
  
}
